<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="editedItem.Overskrift"
        label="Overskrift"
        maxlength="100"
      ></v-text-field>
      <v-textarea
        v-model="editedItem.Beskrivelse"
        filled
        label="Beskrivelse"
        auto-grow
      ></v-textarea>
      <wtDatetime
        v-model="editedItem.OpprettetDato"
        label="Opprettet dato"
        onlyDate
      ></wtDatetime>
      <v-autocomplete
        v-model="editedItem.BrukerID"
        item-text="Description"
        item-value="Value"
        :items="BrukerItems"
        label="Bruker"
      ></v-autocomplete>
      <v-text-field
        v-model.number="editedItem.AvviksKost"
        label="Avviks kostnad"
        hide-details
        single-line
        type="number"
      ></v-text-field>
      <wtMultiSelect
        v-model="editedItem.itemsAvvikVedlegg"
        item-text="Description"
        item-value="Value"
        :items="VedleggMetaItems"
        label="Vedlegg"
      ></wtMultiSelect>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";
import wtDatetime from "../components/wtDatetime.vue";
import wtMultiSelect from "../components/wtMultiSelect.vue";

export default {
  extends: KeyValue,
  name: "dlgAvvik",
  components: {
    wtDatetime,
    wtMultiSelect,
  },

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    keyTaskID: Number,
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  created() {
    this.GetBrukerCode();
    this.GetVedleggMetaCode();
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
